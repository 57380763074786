













































































































































































import { Component } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import ActionType from '@/utils/enums/ActionType';
import Order from '@/models/graphql/Order';
import { OrderFilter } from '@/graphql/_Filters/OrderFilter';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import { formatInTimeZone } from 'date-fns-tz';
import FooterSignature from '@/components/FooterSignature.vue';
import OrderStatus from '@/utils/enums/OrderStatus';
import FormatHelper from '@/utils/helpers/FormatHelper';
import OrderPaymentCharge from '@/models/graphql/OrderPaymentCharge';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';

const orderStore = namespace('OrderStore');

@Component({
  components: { FooterSignature },
})
export default class Invoice extends BreakpointWrapper {
  @orderStore.Action(ActionType.GET_ONE)
  private getOrder!: (payload: { filter: OrderFilter }) => Promise<Order | undefined>;

  @orderStore.Getter
  private order!: Order;

  @orderStore.Action
  private getPaymentIntentCharge!: (payload: { orderUid: string }) => Promise<OrderPaymentCharge>;

  @State
  private dateLocale!: Locale;

  private orderStatus = OrderStatus;

  private paymentCharge: OrderPaymentCharge | null = null;

  private get formattedCreatedTime(): string {
    if (this.order.creationTime) {
      const creationTime = DateTimeHelper
        .toLocal(new Date((this.order.creationTime as unknown) as string));
      return `${formatInTimeZone(creationTime, DateTimeHelper.currentTimeZone(), 'PP', { locale: this.dateLocale })}`;
    }
    return '';
  }

  private get getRights(): string {
    return this.$tc('footer-component.all-right-reserved', 0, {
      currentYear: new Date().getFullYear(),
    });
  }

  private get totalPrice(): string {
    return FormatHelper.formatCurrency((this.order.totalPrice || 0) / 100 || 0);
  }

  private get orderNumber(): string {
    return FormatHelper.formatOrderNumber(this.order.id || 0);
  }

  created(): void {
    this.getOrder({ filter: { uid: this.$route.params.orderId } });
    this.getPaymentIntentCharge({ orderUid: this.$route.params.orderId })
      .then((paymentCharge: OrderPaymentCharge) => {
        this.paymentCharge = paymentCharge;
      });
  }

  private getTotalPriceByPackage(quantity: number, price: number): string {
    return this.formatPrice((quantity * price));
  }

  // eslint-disable-next-line class-methods-use-this
  private formatPrice(price: number): string {
    return FormatHelper.formatCurrency((price || 0) / 100 || 0);
  }
}
